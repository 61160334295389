import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDataProvider } from 'react-admin';
import { Box, Tab } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { BackLink } from '@logora/debate.action.back_link';
import { Box as UiBox } from '@logora/parliament.ui.box';
import { Label } from '@logora/parliament.ui.label';
import { MemberBox } from '@logora/parliament.parliament_member.member_box';
import { AssembleeHemicycle } from '@logora/parliament.parliament_chamber.assemble_hemicycle';
import { ChamberLabel } from '@logora/parliament.parliament_chamber.chamber_label';
import { MultiList } from '../search/MultiList';
import { mandatData } from '../mandats';
import styles from "./OrganeShow.module.scss"

export const OrganeShow = () => {
    const { organeUid } = useParams();
    const [organe, setOrgane] = useState(null);
    const dataProvider = useDataProvider();
    const [currentTab, setCurrentTab] = useState(0);

    const mandatsFilters = [
        { field: "organeRefUid", hidden: true, defaultValue: organeUid, type: 'text' },
        { field: "actif", hidden: true, label: "Actif", type: 'text' }
    ];

    const compositionDataset = {
        'mandats': Object.assign(mandatData, { filters: mandatsFilters})
    }

    useEffect(() => {
        fetchElement();
    }, []);

    const fetchElement = useCallback(async () => {
        try {
            const response = await dataProvider.getOne(`organes`, {
                id: organeUid,
                include: ['organeParentRef', 'acteurRef']
            });
            const organe = response.data;
            if (organe) {
                setOrgane(organe);
            }
        } catch (error) {
            console.log(error);
        }
    }, [dataProvider]);

    if (!organe) return <div>Loading...</div>;

    return (
        <div className={styles.container}>
            <BackLink text={"Voir tous les organes"} to={"#/parliament/recherche/organes"} />
            <div className={styles.header}>
                {organe.chambre && <ChamberLabel chamber={organe.chambre} />}
                <Label text={organe.type} />
            </div>
            <div className={styles.title}>
                {organe.libelle}
            </div>
            <div className={styles.presentation}>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="start"
                    gap="0.5em"
                    sx={{ backgroundColor: '#f9f9f9', padding: '15px', width: '100%' }}
                >
                    <Box display="grid" gridTemplateColumns="1fr 1fr" gap="1em">
                        {organe.positionPolitique &&
                            <Box display="flex" flexDirection="column">
                                <UiBox
                                    className={styles.uiBox}
                                    title="Position"
                                >
                                    {organe.positionPolitique}
                                </UiBox>
                            </Box>
                        }
                        <Box display="flex" flexDirection="column">
                            <UiBox
                                className={styles.uiBox}
                                title="Effectif"
                            >
                                <>{organe.nombreMembres} membres</>
                            </UiBox>
                        </Box>
                        {organe.organeParentRef &&
                            <Box display="flex" flexDirection="column">
                                <UiBox
                                    className={styles.uiBox}
                                    title="Appartenance"
                                >
                                    {organe.organeParentRef.libelle}
                                </UiBox>
                            </Box>
                        }
                    </Box>
                    <Box flex={2} className={styles.svgContainer}>
                        <AssembleeHemicycle
                            className={styles.svgIcon}
                            highlightedSeats={[
                            ]}
                        />
                    </Box>
                </Box>
            </div>
            <div className={styles.corps}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={currentTab}
                            onChange={(e, tab) => setCurrentTab(tab)}
                            sx={{
                                [`& .${tabsClasses.indicator}`]: {
                                    backgroundColor: '#434343 !important'
                                }
                            }}
                        >
                            <Tab className={styles.tab} label="Travaux" />
                            <Tab className={styles.tab} label="Composition" />
                        </Tabs>
                    </Box>
                    {currentTab === 0 && (
                        <div role="tabpanel" value={0} index={0}>
                            Item one
                        </div>
                    )}
                    {currentTab === 1 && (
                        <div role="tabpanel" value={1} index={1}>
                            <MultiList datasets={compositionDataset} showSearch={false} navigateOnChange={false} />
                        </div>
                    )}
                </Box>
            </div>
        </div>
    );
};
