import React from 'react';
import SectionTitle from '../../utils/SectionTitle';
import { DashboardDatePicker } from '../../debate/dashboard/DashboardDatePicker';
import {
    Box,
    CardContent,
    MenuItem,
    Select,
    Stack,
    Typography
} from '@mui/material';
import { LineChart, lineElementClasses, PieChart } from '@mui/x-charts';
import { Check, Create, Favorite, PersonAdd, Send } from '@mui/icons-material';
import { MetricCard } from './MetricCard';
import { StatCard } from './StatCard';

const statistics = {
    followers: {
        providers: {
            facebook: {
                value: 158_675,
                trend: 'up',
                trendPercentage: 12.5
            },
            instagram: {
                value: 500_845,
                trend: 'down',
                trendPercentage: 1.5
            },
            linkedin: {
                value: 1_000_000,
                trend: 'down',
                trendPercentage: 6.5
            },
            tiktok: {
                value: 245_800,
                trend: 'down',
                trendPercentage: 1.5
            },
            youtube: {
                value: 1_000_000,
                trend: 'up',
                trendPercentage: 6.5
            }
        },

        total: 158_675 + 500_845 + 1_000_000 + 245_800 + 1_000_000,
        trend: 'down',
        trendPercentage: 6.5
    },

    comments: {
        providers: {
            facebook: 100_000,
            instagram: 500_000,
            linkedin: 300_000,
            tiktok: 100_000,
            youtube: 100_000
        },
        total: 100_000 + 500_000 + 300_000 + 100_000 + 100_000
    },

    accepted_contents: {
        providers: {
            facebook: {
                value: 0.8,
                trend: 'down',
                trendPercentage: 1.5
            },
            instagram: {
                value: 0.75,
                trend: 'down',
                trendPercentage: 1.5
            },
            linkedin: {
                value: 0.9,
                trend: 'up',
                trendPercentage: 0.5
            },
            tiktok: {
                value: 0.85,
                trend: 'down',
                trendPercentage: 0.65
            },
            youtube: {
                value: 0.8,
                trend: 'up',
                trendPercentage: 0.1
            }
        },
        global: 0.85,
        trend: 'up',
        trendPercentage: 6.5
    },

    new_users: {
        providers: {
            facebook: 123_456,
            instagram: 50_000,
            linkedin: 10_000,
            tiktok: 50_000,
            youtube: 60_000
        },
        total: 123_456 + 50_000 + 10_000 + 50_000 + 60_000
    },

    posts: {
        providers: {
            facebook: 100,
            instagram: 50,
            linkedin: 30,
            tiktok: 100,
            youtube: 100
        },
        total: 100 + 50 + 30 + 100 + 100
    },

    likes: {
        providers: {
            facebook: 100_000,
            instagram: 500_000,
            linkedin: 300_000,
            tiktok: 100_000,
            youtube: 100_000
        },
        total: 100_000 + 500_000 + 300_000 + 100_000 + 100_000
    },

    shares: {
        providers: {
            facebook: 100,
            instagram: 50,
            linkedin: 30,
            tiktok: 100,
            youtube: 100
        },
        total: 100 + 50 + 30 + 100 + 100
    },

    start_date: '2024-07-01',
    end_date: '2024-08-01'
};

const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const amtData = [2400, 2210, 0, 2000, 2181, 2500, 2100];
const xLabels = [
    '28/07/24',
    '29/07/24',
    '30/07/24',
    '31/07/24',
    '01/08/24',
    '02/08/24',
    '03/08/24'
];

function toPercentageString(value: number) {
    return (value * 100).toFixed(1) + '%';
}

export function Dashboard() {
    const [dateRange, setDateRange] = React.useState();
    const [selectedProvider, setSelectedProvider] =
        React.useState<string>('all');

    return (
        <Box sx={{ marginBottom: 5, maxWidth: '95%' }}>
            <SectionTitle title="Statistiques" />

            <Stack sx={{ marginTop: 3 }}>
                <DashboardDatePicker setDateRangeCallback={setDateRange} />
            </Stack>

            <Stack direction="row" gap={5} marginTop={5}>
                <StatCard>
                    <CardContent>
                        <Typography variant="h2" fontSize={18} marginBottom={3}>
                            Répartition des followers
                        </Typography>

                        <Stack direction="row" gap={2}>
                            <PieChart
                                series={[
                                    {
                                        data: Object.entries(
                                            statistics.followers.providers
                                        ).map(([provider, stats]) => ({
                                            id: provider,
                                            label: provider,
                                            value: stats.value
                                        })),
                                        innerRadius: 70,
                                        highlightScope: {
                                            fade: 'global',
                                            highlight: 'item'
                                        }
                                    }
                                ]}
                                width={400}
                                height={200}
                                slotProps={{
                                    legend: {
                                        itemMarkWidth: 10,
                                        itemMarkHeight: 10
                                    }
                                }}
                            />
                        </Stack>
                    </CardContent>
                </StatCard>

                <StatCard>
                    <CardContent>
                        <Typography variant="h2" fontSize={18} marginBottom={3}>
                            Répartition des commentaires
                        </Typography>

                        <Stack direction="row" gap={2}>
                            <PieChart
                                series={[
                                    {
                                        data: Object.entries(
                                            statistics.comments.providers
                                        ).map(([provider, value]) => ({
                                            id: provider,
                                            label: provider,
                                            value: value
                                        })),
                                        innerRadius: 70,
                                        highlightScope: {
                                            fade: 'global',
                                            highlight: 'item'
                                        }
                                    }
                                ]}
                                width={400}
                                height={200}
                                slotProps={{
                                    legend: {
                                        itemMarkWidth: 10,
                                        itemMarkHeight: 10
                                    }
                                }}
                            />
                        </Stack>
                    </CardContent>
                </StatCard>
            </Stack>

            <Select
                defaultValue="all"
                sx={{
                    marginTop: 3,
                    minWidth: 200,
                    textTransform: 'capitalize'
                }}
                onChange={(e) => {
                    setSelectedProvider(e.target.value);
                }}
            >
                {Object.keys(statistics.accepted_contents.providers).map(
                    (provider) => (
                        <MenuItem
                            key={provider}
                            value={provider}
                            sx={{ textTransform: 'capitalize' }}
                        >
                            {provider}
                        </MenuItem>
                    )
                )}
                <MenuItem value="all">Tous</MenuItem>
            </Select>

            <Stack
                sx={{ marginTop: 2 }}
                direction="row"
                gap={4}
                flexWrap="wrap"
            >
                <MetricCard
                    title="Contenus acceptés"
                    trend={
                        selectedProvider === 'all'
                            ? (statistics.accepted_contents.trend as
                                  | 'up'
                                  | 'down')
                            : (statistics.accepted_contents.providers[
                                  selectedProvider as keyof typeof statistics.accepted_contents.providers
                              ].trend as 'up' | 'down')
                    }
                    trendPercentage={
                        selectedProvider === 'all'
                            ? statistics.accepted_contents.trendPercentage
                            : statistics.accepted_contents.providers[
                                  selectedProvider as keyof typeof statistics.accepted_contents.providers
                              ].trendPercentage
                    }
                    value={
                        selectedProvider === 'all'
                            ? toPercentageString(
                                  statistics.accepted_contents.global
                              )
                            : toPercentageString(
                                  statistics.accepted_contents.providers[
                                      selectedProvider as keyof typeof statistics.accepted_contents.providers
                                  ].value
                              )
                    }
                    icon={
                        <Box
                            sx={{
                                display: 'grid',
                                placeItems: 'center',
                                borderRadius: '1.3em',
                                padding: 1.5,
                                backgroundColor: 'rgba(254, 197, 61, 0.4)'
                            }}
                        >
                            <Check sx={{ fontSize: 32, color: '#FEC53D' }} />
                        </Box>
                    }
                />

                <MetricCard
                    title="Nouveaux utilisateurs"
                    trend="down"
                    trendPercentage={6.5}
                    value={
                        selectedProvider === 'all'
                            ? statistics.new_users.total.toLocaleString()
                            : statistics.new_users.providers[
                                  selectedProvider as keyof typeof statistics.new_users.providers
                              ].toLocaleString()
                    }
                    icon={
                        <Box
                            sx={{
                                display: 'grid',
                                placeItems: 'center',
                                borderRadius: '1.3em',
                                padding: 1.5,
                                backgroundColor: 'rgba(255, 144, 102, 0.4)'
                            }}
                        >
                            <PersonAdd
                                sx={{ fontSize: 32, color: '#FF5618' }}
                            />
                        </Box>
                    }
                />

                <MetricCard
                    title="Posts"
                    trend="up"
                    trendPercentage={12.5}
                    value={
                        selectedProvider === 'all'
                            ? statistics.posts.total.toLocaleString()
                            : statistics.posts.providers[
                                  selectedProvider as keyof typeof statistics.posts.providers
                              ].toLocaleString()
                    }
                    icon={
                        <Box
                            sx={{
                                display: 'grid',
                                placeItems: 'center',
                                borderRadius: '1.3em',
                                padding: 1.5,
                                backgroundColor: 'rgba(255, 128, 168, 0.4)'
                            }}
                        >
                            <Create sx={{ fontSize: 32, color: '#FF004C' }} />
                        </Box>
                    }
                />

                <MetricCard
                    title="Likes"
                    trend="down"
                    trendPercentage={1.5}
                    value={
                        selectedProvider === 'all'
                            ? statistics.likes.total.toLocaleString()
                            : statistics.likes.providers[
                                  selectedProvider as keyof typeof statistics.likes.providers
                              ].toLocaleString()
                    }
                    icon={
                        <Box
                            sx={{
                                display: 'grid',
                                placeItems: 'center',
                                borderRadius: '1.3em',
                                padding: 1.5,
                                backgroundColor: 'rgba(39, 225, 131, 0.4)'
                            }}
                        >
                            <Favorite sx={{ fontSize: 32, color: '#05DB2C' }} />
                        </Box>
                    }
                />

                <MetricCard
                    title="Partages"
                    trend="up"
                    trendPercentage={12.5}
                    value={
                        selectedProvider === 'all'
                            ? statistics.shares.total.toLocaleString()
                            : statistics.shares.providers[
                                  selectedProvider as keyof typeof statistics.shares.providers
                              ].toLocaleString()
                    }
                    icon={
                        <Box
                            sx={{
                                display: 'grid',
                                placeItems: 'center',
                                borderRadius: '1.3em',
                                padding: 1.5,
                                backgroundColor: 'rgba(0, 122, 255, 0.4)'
                            }}
                        >
                            <Send sx={{ fontSize: 32, color: '#0076FD' }} />
                        </Box>
                    }
                />
            </Stack>

            <StatCard sx={{ marginTop: 5 }} variant="elevation" elevation={10}>
                <CardContent>
                    <Typography variant="h2" fontSize={18} marginBottom={3}>
                        Nombre de commentaires
                    </Typography>

                    <LineChart
                        height={400}
                        series={[
                            {
                                data: uData,
                                label: 'facebook',
                                area: true,
                                stack: 'total',
                                showMark: false
                            },
                            {
                                data: pData,
                                label: 'instagram',
                                area: true,
                                stack: 'total',
                                showMark: false
                            },
                            {
                                data: amtData,
                                label: 'youtube',
                                area: true,
                                stack: 'total',
                                showMark: false
                            },
                            {
                                data: amtData,
                                label: 'tiktok',
                                area: true,
                                stack: 'total',
                                showMark: false
                            }
                        ]}
                        xAxis={[{ scaleType: 'point', data: xLabels }]}
                        sx={{
                            [`& .${lineElementClasses.root}`]: {
                                display: 'none'
                            }
                        }}
                        slotProps={{
                            legend: {
                                position: {
                                    horizontal: 'middle',
                                    vertical: 'top'
                                }
                            }
                        }}
                    />
                </CardContent>
            </StatCard>
        </Box>
    );
}
