import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDataProvider } from 'react-admin';
import { BackLink } from '@logora/debate.action.back_link';
import { Box } from '@mui/material';

export const ReunionShow = () => {
    const { reunionUid } = useParams();
    const [reunion, setReunion] = useState(null);
    const dataProvider = useDataProvider();

    useEffect(() => {
        fetchElement();
    }, []);

    const fetchElement = useCallback(async () => {
        try {
            const response = await dataProvider.getOne(`reunions`, {
                id: reunionUid
            });
            const reunion = response.data;
            if (reunion) {
                setReunion(reunion);
            }
        } catch (error) {
            console.log(error);
        }
    }, [dataProvider]);

    if (!reunion) return <div>Loading...</div>;

    return (
        <>
            <BackLink text={"Voir toutes les reunions"} to={"#/parliament/recherche/reunions"} />
            <Box sx={{ marginTop: "2em" }}>
                {reunion.uid}
            </Box>
        </>
    );
};
