import { ChamberLabel } from "@logora/parliament.parliament_chamber.chamber_label"
import { GroupLabel } from "@logora/parliament.parliament_group.group_label"
import { MemberBox } from "@logora/parliament.parliament_member.member_box"
import { Label } from "@logora/parliament.ui.label"

export const formatDate = (field, item) => {
    return field && (field in item) ? new Date(item[field]).toLocaleDateString() : '-'
}

export const formatCapitalize = (field, item) => {
    if (!(field in item)) {
        return '-'
    }
    return item[field].charAt(0).toUpperCase() + item[field].slice(1)
}

export const formatNumber = (field, item) => {
    return item[field]?.toString() || '0';
}

export const formatNumero = (field, item) => {
    if (item[field]) {
        return `n°${item[field]?.toString()}`
    }
}

export const formatLabel = (field, item) => {
    if (item[field]) {
        return <Label text={item[field]} />
    }
}

export const formatChambre = (field, item) => {
    if (item[field]) {
        return <ChamberLabel chamber={item[field]} short={true} />
    }
}

export const formatGroupeParlementaire = (field, item) => {
    if (item[field] && item[field].libelle) {
        return <GroupLabel groupName={item[field]?.libelle} color={item[field]?.couleurAssociee || "#000000"} />
    }
}

export const formatOrgane = (field, item) => {
    return item[field]?.libelle
}

export const formatActeur = (field, item) => {
    if(item[field]) {
        return <MemberBox firstName={item[field].prenom} lastName={item[field].nom} uid={item[field].uid} />
    }
}

export const formatTexteLegislatif = (field, item) => {
    return item[field]?.titre
}

export const formatDateNaisToAge = (dateNais) => {
    const currentDate = new Date();
    const birthDate = new Date(dateNais);
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())) {
        age--;
    }
    
    return age;
}