import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDataProvider } from 'react-admin';
import { Label } from '@logora/parliament.ui.label';
import { Box } from '@logora/parliament.ui.box';
import { BackLink } from '@logora/debate.action.back_link';
import { MemberBox } from '@logora/parliament.parliament_member.member_box';
import styles from "./DossierShow.module.scss"

export const DossierShow = () => {
    const { dossierUid } = useParams();
    const [dossier, setDossier] = useState(null);
    const dataProvider = useDataProvider();

    useEffect(() => {
        fetchElement();
    }, []);

    const fetchElement = useCallback(async () => {
        try {
            const response = await dataProvider.getOne(`dossiers`, {
                id: dossierUid
            });
            const dossier = response.data;
            if (dossier) {
                setDossier(dossier);
            }
        } catch (error) {
            console.log(error);
        }
    }, [dataProvider]);

    if (!dossier) return <div>Loading...</div>;

    return (
        <div className={styles.container}>
            <BackLink text={"Voir tous les dossiers"} to={"#/parliament/recherche/dossiers"} />
            <div className={styles.header}>
                <Label text={dossier.typeInitiative} />
            </div>
            <div className={styles.title}>
                {dossier.titre}
            </div>
            <div className={styles.presentation}>
                <div className={styles.rapporteur}>
                    <Box title="Rapporteur" className={styles.boxContainer}>
                        <MemberBox firstName={"Aude"} lastName={"Dupont"} uid="PA1001" />
                    </Box>
                </div>
                <div className={styles.commission}>
                    <Box title="Commission" className={styles.boxContainer}>
                        <div>Commission des lois</div>
                    </Box>
                </div>
                <div className={styles.date}>
                    <Box title="Date de mise à jour" className={styles.boxContainer}>
                        <div>{dossier.dateDernierActe}</div>
                    </Box>
                </div>
            </div>
        </div>
    );
};
