import { formatDate, formatNumero, formatLabel, formatActeur } from '../formatters'
import { DossierIcon } from '@logora/parliament.icons.regular_icons';

const formatActe = (field, item) => {
    switch (item.codeDernierActe) {
        case 'AN1':
            return 'Texte déposé à l’Assemblée nationale';
        case 'SN1':
            return 'Texte déposé au Sénat';
        case 'AN1-COM':
            return 'AN : 1ère lecture en commission';
        case 'AN1-DEBATS-SEANCE':
            return 'AN : 1ère lecture en séance publique';
        case 'SN1-COM':
            return 'Sénat : 1ère lecture en commission';
        case 'SN1-DEBATS-SEANCE':
            return 'Sénat : 1ère lecture en séance publique';
        case 'AN2-COM':
            return 'AN : 2ème lecture en commission';
        case 'AN2-DEBATS-SEANCE':
            return 'AN : 2ème lecture en séance publique';
        case 'SN2-COM':
            return 'Sénat : 2ème lecture en commission';
        case 'SN2-DEBATS-SEANCE':
            return 'Sénat : 2ème lecture en séance publique';
        case 'CMP':
            return 'Discussion en commission mixte paritaire';
        case 'CMP-DEBATS-AN':
            return 'AN : Discussion du texte de la CMP en séance publique';
        case 'CMP-DEBATS-SN':
            return 'Sénat : Discussion du texte de la CMP en séance publique';
        case 'CC':
            return 'Saisine du conseil constitutionnel';
        case 'PROM':
            return 'Texte adopté';
        default:
            return '-';
    }
}

const dossiersFields = {
    'dateDernierActe': { label: "Date de mise à jour", field: "dateDernierActe", sort: "dateDernierActe", format: formatDate },
    'typeInitiative': { label: "Type", field: "typeInitiative", format: formatLabel },
    'acteurPrincipalRef': { label: "Auteur", field: "acteurPrincipalRef", include: "acteurPrincipalRef", format: formatActeur },
    'numero': { label: "Numéro", field: "numero", format: formatNumero },
    'titre': { label: "Nom", field: "titre" },
    'codeDernierActe': { label: "État", field: "codeDernierActe", format: formatActe }
};

const dossiersFilters = [
    { field: "uid", label: 'UID', type: 'text' },
    { field: "typeInitiative", label: "Type", type: 'select', options: [
        { value: "" , label: "Tous" },
        { value: "Proposition de loi" , label: "Proposition de loi" },
        { value: "Projet de loi", label: "Projet de loi" },
        { value: "Commission d'enquête", label: "Commission d'enquête" },
        { value: "Proposition de résolution", label: "Proposition de résolution" },
        { value: "Résolution", label: "Résolution" },
        { value: "Rapport d'information", label: "Rapport d'information" }
    ]}
];

export const dossierData = {
    key: "dossiers",
    name: "Dossiers",
    filters: dossiersFilters,
    fields: dossiersFields,
    icon: <DossierIcon width={20} height={20} />
}