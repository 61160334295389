import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { SearchBar } from './SearchBar';
import { ListNavigation } from './ListNavigation';
import { FilterBar } from './FilterBar';
import { List } from './List';

export const MultiList = ({ datasets = [], showSearch = true, navigateOnChange = true, hideOverflow = false, height = 'none' }) => {
    const { dataset = Object.keys(datasets)[0] } = useParams();
    const [searchParams] = useSearchParams();

    const getDefaultFilters = () => {
        if (dataset) {
            const initFilters = {}
            datasets[dataset].filters.map(f => {
                if (f.defaultValue !== null) {
                    initFilters[f.field] = f.defaultValue
                }
            })
            return initFilters
        }
    }

    const [activeList, setActiveList] = useState();
    const [filters, setFilters] = useState(getDefaultFilters());
    const [globalFilters, setGlobalFilters] = useState({});
    const [search, setSearch] = useState(searchParams.get("query"));

    useEffect(() => {
        if (dataset) {
            setActiveList(dataset)
            setGlobalFilters({})
        }
    }, [dataset])

    const handleNavChange = (list) => {
        setActiveList(list);
    };

    const handleSearchChange = (query) => {
        setSearch(query)
    };

    const handleFilterChange = (filter, value) => {
        if (filter.type === "boolean" || value !== null) {
            setFilters(prevFilters => {
                return { ...prevFilters, [filter.field]: value }
            });
        } else {
            setFilters(prevFilters => {
                const { [filter.field]: _, ...rest } = prevFilters;
                return rest;
            });
        }
    }

    return (
        <>
            {activeList &&
                <Box sx={{ display: 'flex', flexDirection: 'column', height: height }}>
                    <Box sx={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white' }}>
                        {showSearch && <SearchBar onSearchChange={handleSearchChange} />}
                        <ListNavigation
                            datasets={datasets}
                            activeList={activeList}
                            onNavChange={handleNavChange}
                            search={search}
                            filters={filters}
                            navigateOnChange={navigateOnChange}
                        />
                        <FilterBar
                            filters={datasets[activeList].filters}
                            onFilterChange={handleFilterChange}
                        />
                    </Box>
                    <Box sx={{ flexGrow: 1, overflow: 'auto', marginRight: "10px" }}>
                        <List
                            dataset={datasets[activeList]}
                            filters={filters}
                            search={search}
                            hideOverflow={hideOverflow}
                        />
                    </Box>
                </Box>
            }
        </>
    );
};
