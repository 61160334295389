import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDataProvider } from 'react-admin';
import { Box, Typography, Button } from '@mui/material';
import { Avatar } from '@logora/debate.user.avatar';
import { Box as UiBox } from '@logora/parliament.ui.box';
import { GroupLabel } from '@logora/parliament.parliament_group.group_label';
import { MemberBox } from '@logora/parliament.parliament_member.member_box';
import { AssembleeHemicycle } from '@logora/parliament.parliament_chamber.assemble_hemicycle';
import { BackLink } from '@logora/debate.action.back_link';
import { formatDateNaisToAge } from '../formatters';
import cx from 'classnames';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import XIcon from '@mui/icons-material/X';
import LanguageIcon from '@mui/icons-material/Language';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import useFetchActeur from './useFetchActeur';
import ActeurDashboard from './ActeurDashboard';
import useFetchCollaborateurs from './useFetchCollaborateurs';
import styles from './ActeurShow.module.scss'

export const ActeurShow = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const dataProvider = useDataProvider();
    const { acteurUid } = useParams();
    const {
        acteur,
        adresses,
        loading: acteurLoading,
        error: acteurError
    } = useFetchActeur(dataProvider, acteurUid);
    const { collaborateurs, loading: collaborateursLoading, error: collaborateursError } = useFetchCollaborateurs(dataProvider, acteur?.mandatPrincipal?.uid)
    const baseUrl = process.env.REACT_APP_TRICOTEUSES_IMAGES_URL;
    const stripPrefix = (userUid) => userUid.replace('PA', '');

    const displayContacts = () => {
        const phone = adresses.find(
            (adresse) => adresse.typeLibelle === 'Téléphone'
        );
        const email = adresses.find((adresse) => adresse.typeLibelle === 'Mèl');
        const publishedAdressePostale = adresses.find(
            (adresse) =>
                adresse.typeLibelle === 'Adresse publiée de circonscription'
        );
        const defaultAdressePostale = adresses.find(
            (adresse) => adresse.typeLibelle === 'Adresse officielle'
        );
        const adresseToDisplay =
            publishedAdressePostale || defaultAdressePostale;

        const adressePostale = adresseToDisplay
            ? `${adresseToDisplay.numeroRue || ""} ${adresseToDisplay.nomRue || ""} ${adresseToDisplay.codePostal || ""} ${adresseToDisplay.ville || ""}`
            : 'Adresse non disponible';

        return (
            <Box display={'flex'} flexDirection={'column'}>
                <span className={styles.contactLine}>
                    <PhoneIcon sx={{ marginRight: "0.5em"}} /> {phone ? phone.valElec : 'Non disponible'}
                </span>
                <span className={styles.contactLine}>
                    <HomeIcon sx={{ marginRight: "0.5em"}} /> {adressePostale}
                </span>
                <span className={styles.contactLine}>
                    <EmailIcon sx={{ marginRight: "0.5em"}} /> {email ? email.valElec : 'Non disponible'}
                </span>
            </Box>
        );
    };

    const displayWebAddresses = () => {
        const website = adresses.find((adresse) => adresse.typeLibelle === 'Site internet') ? "https://" + adresses.filter(a => a.typeLibelle === "Site internet")[0]?.valElec : undefined;
        const twitter = acteur.compteTwitter ? `https://x.com/${acteur.compteTwitter?.substring(1)}` : undefined;
        
        return (
            <>
                {twitter &&
                    <a
                        target="_blank"
                        rel="noreferrer noopener"
                        className={cx(styles.button, styles.buttonRounded)}
                        href={twitter}
                    >
                        <XIcon />
                    </a>
                }
                {website &&
                    <a
                        target="_blank"
                        rel="noreferrer noopener"
                        className={cx(styles.button, styles.buttonRounded)}
                        href={website}
                    >
                        <LanguageIcon />
                    </a>
                }
            </>
        );
    };

    if (acteurLoading) return <div>Loading...</div>;
    if (acteurError)
        return (
            <div>Erreur: {acteurError?.message}</div>
        );

    if (!acteur) return <div>Erreur lors de la récupération de l'acteur</div>;

    return (
        <div className={styles.mainContainer}>
            <BackLink
                text={'Voir tous les acteurs'}
                to={'#/parliament/recherche/acteurs'}
            />
            <Box
                className={styles.avatarBackground}
                display={'flex'}
                gap={'2em'}
                sx={{ marginTop: '1em' }}
            >
                <Avatar
                    avatarUrl={`${baseUrl}${stripPrefix(acteur.uid)}.jpg`}
                    size={124}
                />
                <Box display={'column'}>
                    <Typography sx={{ fontSize: '34px' }}>
                        {acteur.prenom} {acteur.nom}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#777777' }}>
                        {acteur.circonscription?.libelle}
                    </Typography>
                </Box>
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    marginLeft={'auto'}
                    gap="0.5em"
                >
                    { displayWebAddresses() }
                    <Button
                        className={`${styles.button}`}
                        handleClick={() => null}
                        startIcon={<StarOutlineIcon />}
                    >
                        Suivre
                    </Button>
                </Box>
            </Box>

            <Box
                display="flex"
                flexDirection="row"
                alignItems="start"
                gap="0.5em"
                sx={{ backgroundColor: '#f9f9f9', padding: '15px' }}
            >
                <Box display="grid" gridTemplateColumns="1fr 1fr" gap="1em">
                    <Box display="flex" flexDirection="column">
                        <UiBox
                            className={styles.uiBox}
                            title="Groupe politique"
                        >
                            {acteur.groupeParlementaire && (
                                <GroupLabel
                                    groupName={
                                        acteur.groupeParlementaire?.libelle
                                    }
                                    color={
                                        acteur.groupeParlementaire
                                            ?.couleurAssociee
                                    }
                                    size="small"
                                />
                            )}
                        </UiBox>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <UiBox className={styles.uiBox} title="Âge">
                            <div>
                                {formatDateNaisToAge(acteur.dateNais)} ans
                            </div>
                        </UiBox>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <UiBox
                            className={styles.uiBox}
                            title="Commission"
                            helperText={acteur.commissionPermanente?.libelle}
                        >
                            <div>{acteur.commissionPermanente?.libelle}</div>
                        </UiBox>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <UiBox className={styles.uiBox} title="Profession">
                            <div>{acteur.profession}</div>
                        </UiBox>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <UiBox
                            className={styles.uiBox}
                            title="Collaborateurs"
                            helperText="Collaborateurs"
                        >
                            
                            <Box display="flex" flexDirection="column">
                                { collaborateurs?.map(collab => {
                                    return (
                                        <MemberBox
                                            firstName={collab.prenom}
                                            lastName={collab.nom}
                                            uid={collab.uid}
                                            key={collab.id}
                                            size="small"
                                            layout="oneLine"
                                        />
                                    )
                                })}
                            </Box>
                        </UiBox>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <UiBox className={styles.uiBox} title="Contacts">
                            {displayContacts()}
                        </UiBox>
                    </Box>
                </Box>
                <Box flex={2} className={styles.svgContainer}>
                    <AssembleeHemicycle
                        className={styles.svgIcon}
                        highlightedSeats={[
                            {
                                id: acteur.mandatPrincipal?.placeHemicycle,
                                color: acteur.groupeParlementaire
                                    ?.couleurAssociee,
                                label: `Siège ${acteur.mandatPrincipal?.placeHemicycle}`,
                                position: 'top'
                            }
                        ]}
                    />
                </Box>
            </Box>
            <Box sx={{ width: '100%', marginTop: '1em' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={currentTab}
                        onChange={(e, tab) => setCurrentTab(tab)}
                        sx={{
                            [`& .${tabsClasses.indicator}`]: {
                                backgroundColor: '#434343 !important'
                            }
                        }}
                    >
                        <Tab className={styles.tab} label="Tableau de bord" />
                        <Tab
                            className={styles.tab}
                            label="Travaux parlementaires"
                        />
                    </Tabs>
                </Box>
                {currentTab === 0 && (
                    <div role="tabpanel" value={0} index={0}>
                        <ActeurDashboard />
                    </div>
                )}
                {currentTab === 1 && (
                    <div role="tabpanel" value={1} index={1}>
                        Item Two
                    </div>
                )}
            </Box>
        </div>
    );
};
