import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDataProvider } from 'react-admin';
import { Label } from '@logora/parliament.ui.label';
import { Box } from '@logora/parliament.ui.box';
import { BackLink } from '@logora/debate.action.back_link';
import { formatDate } from '../formatters';
import { formatType } from './questionsFields';
import { ChamberLabel } from '@logora/parliament.parliament_chamber.chamber_label';
import { MemberBox } from '@logora/parliament.parliament_member.member_box';
import styles from "./QuestionShow.module.scss"

export const QuestionShow = () => {
    const { questionUid } = useParams();
    const [question, setQuestion] = useState(null);
    const dataProvider = useDataProvider();

    useEffect(() => {
        fetchElement();
    }, []);

    const fetchElement = useCallback(async () => {
        try {
            const response = await dataProvider.getOne(`questions`, {
                id: questionUid,
                include: ["acteurRef", "minIntRef"]
            });
            const question = response.data;
            if (question) {
                setQuestion(question);
            }
        } catch (error) {
            console.log(error);
        }
    }, [dataProvider]);

    if (!question) return <div>Loading...</div>;

    return (
        <div className={styles.container}>
            <BackLink text={"Voir toutes les questions"} to={"#/parliament/recherche/questions"} />
            <div className={styles.header}>
                <ChamberLabel chamber={question.uid.slice(1,3)} />
                <Label text={formatType(question.type, question)} />
            </div>
            <div className={styles.title}>
                {question.titre}
            </div>
            <div className={styles.presentation}>
                <div className={styles.box}>
                    <Box title="Auteur" className={styles.boxContainer}>
                        <MemberBox firstName={question.acteurRef?.prenom} lastName={question.acteurRef?.nom} uid={question.acteurRef?.uid} />
                    </Box>
                </div>
                <div className={styles.box}>
                    <Box title="Ministère intérrogé" className={styles.boxContainer}>
                        <div>{question.minIntRef?.libelleAbrege}</div>
                    </Box>
                </div>
                <div className={styles.box}>
                    <Box title="Date de dépôt" className={styles.boxContainer}>
                        <div>{formatDate("dateDepot", question)}</div>
                    </Box>
                </div>
            </div>
            <div className={styles.corps}>
                <div className={styles.box}>
                    <Box title="Question" className={styles.boxContainer}>
                        <div>{question.texteQuestion}</div>
                    </Box>
                </div>
                <div className={styles.box}>
                    <Box title="Réponse" className={styles.boxContainer}>
                        { question.texteReponse ?
                            <div>{question.texteReponse}</div>
                            :
                            <i>Pas de réponse pour l'instant</i>
                        }
                        
                    </Box>
                </div>
            </div>
        </div>
    );
};
