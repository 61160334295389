import React, { useState, useEffect } from 'react';
import {
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    TableSortLabel,
    TablePagination,
    Paper
} from '@mui/material';
import { useTranslate, useRedirect, useDataProvider } from 'react-admin';
import makeStyles from '@mui/styles/makeStyles';
import { visuallyHidden } from '@mui/utils';

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        borderColor: "#5B7FF1",
    },
    tableHeaderCell: {
        color: "black",
        fontWeight: 'bold',
        padding: "1em",
        cursor: "pointer"
    },
    tableBody: {
        '& tr:nth-of-type(odd)': {
            backgroundColor: "rgba(0, 0, 0, 0.04) !important",
        },
    },
    tableCell: {
        padding: "0.75em"
    },
    hoverRow: {
        '&:hover': {
            backgroundColor: "rgba(0, 0, 0, 0.10) !important",
            cursor: 'pointer'
        },
    },
    loader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '10em',
    }
}));

export const List = ({ dataset, filters, search, hideOverflow = false }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const redirectTo = useRedirect();
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);

    function findFirstObjectWithSortKey(obj) {
        for (const key in obj) {
            if (obj[key].hasOwnProperty('defaultSort')) {
                return key;
            }
        }
        return Object.keys(obj)[0];
    }

    const getDefaultSort = () => {
        const fields = dataset.fields
        const firstFieldKey = findFirstObjectWithSortKey(fields);
        return { field: fields[firstFieldKey]["sort"] || undefined, order: 'desc', name: firstFieldKey };
    };

    const [sort, setSort] = useState(getDefaultSort())
    const [page, setPage] = useState(1)
    const [data, setData] = useState([])

    const handleRowClick = (item) => {
        redirectTo(`/parliament/${dataset.key}/${item.uid || item.id}`);
    };

    const fetchListData = () => {
        const includes = Object.values(dataset.fields).reduce((acc, current) => {
            if (current.hasOwnProperty('include')) {
                acc.push(current.include);
            }
            return acc;
        }, []);

        setLoading(true)

        dataProvider.getList(dataset.key, {
            filter: { ...filters },
            pagination: { page: page, perPage: 10 },
            include: includes,
            sort: sort,
            search: search
        })
            .then((res) => {
                if (res) {
                    setData(res.data);
                    setTotal(res.total)
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(`Failed to fetch ${dataset.key} data:`, error);
                setLoading(false);
            });
    };

    const handleSort = (key) => {
        const sortField = dataset.fields[key].sort || dataset.fields[key].field;

        setSort((prevSort) => {
            const order = prevSort.name === key && prevSort.order === "desc" ? "asc" : "desc";
            return { field: sortField, order: order, name: key }
        });
    };

    const handlePageChange = (newPage) => {
        setPage(newPage)
    };

    useEffect(() => {
        setPage(1)
        setSort(getDefaultSort())
    }, [dataset, search, filters]);

    useEffect(() => {
        if(hideOverflow) {
            document.body.style.overflow = 'hidden';
        }
        fetchListData();
        return () => {
            if(hideOverflow) {
                document.body.style.overflow = 'auto';
            }
        };
    }, [sort, page]);

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    {loading ?
                        <div className={classes.loader}>
                            <CircularProgress />
                        </div>
                        :
                        <>
                            <TableHead>
                                <TableRow className={classes.tableHeader}>
                                    {Object.keys(dataset.fields).map((key) => (
                                        <TableCell
                                            key={dataset.fields[key].field}
                                            className={classes.tableHeaderCell}
                                            sortDirection={sort.name === key ? sort.order : false}
                                        >
                                            <TableSortLabel
                                                active={sort.name === key}
                                                direction={sort.order}
                                                onClick={() => handleSort(key)}
                                            >
                                                {dataset.fields[key].label}
                                                {sort.name === key ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {sort.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tableBody}>
                                {data.map((item, rowIndex) => (
                                    <TableRow
                                        key={rowIndex}
                                        className={classes.hoverRow}
                                        onClick={() => handleRowClick(item)}
                                    >
                                        {Object.keys(dataset.fields).map((key) => (
                                            <TableCell key={dataset.fields[key].field} className={classes.tableCell}>
                                                {dataset.fields[key].format ?
                                                    dataset.fields[key].format(dataset.fields[key].field, item)
                                                    :
                                                    item[dataset.fields[key].field]?.toString() || '-'
                                                }
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow align='left' className={classes.filterRow}>
                                    <TablePagination
                                        page={page - 1}
                                        count={total}
                                        labelRowsPerPage={translate("pos.main.rows_per_page")}
                                        rowsPerPage={10}
                                        rowsPerPageOptions={[]}
                                        onPageChange={(event, newPage) => { handlePageChange(newPage + 1) }}
                                        labelDisplayedRows={({ from, to, count }) => {
                                            return `${from}-${to} ${translate("pos.main.from_to_pagination")} ${count}`
                                        }}
                                    />
                                </TableRow>
                            </TableFooter>
                        </>
                    }
                </Table>
            </TableContainer>
        </>
    );
};