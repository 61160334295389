import { formatDate, formatOrgane, formatChambre } from "../formatters";
import { VoteIcon } from "@logora/parliament.icons.regular_icons";

const scrutinsFields = {
    dateScrutin: { label: "Date", field: "dateScrutin", sort: "dateScrutin", format: formatDate },
    'chambre': { label: 'Chambre', field: 'chambre', format: formatChambre },
    type: { label: "Type", field: "type" },
    organeRef: { label: "Organe", field: "organeRef", format: formatOrgane },
    titre: { label: "Objet", field: "titre" },
    nombreVotants: { label: "Votes", field: "nombreVotants" }, // calculer avec l'autre champ (votes pour)
    code: { label: "Résultats", field: "code" },
}

const scrutinsFilters = [
    { field: "uid", label: 'UID', type: 'text' },
];

export const scrutinData = {
    key: "scrutins",
    name: "Scrutins",
    filters: scrutinsFilters,
    fields: scrutinsFields,
    icon: <VoteIcon width={20} height={20} />
}