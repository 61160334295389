import BooleanField from "../components/BooleanField";
import { OrganeIcon } from "@logora/parliament.icons.regular_icons";
import { formatChambre, formatNumber, formatLabel } from "../formatters";

const formatActif = (field, item) => {
    return <BooleanField condition={!item.dateFin} />
}

const organesFields = {
    'chambre': { label: 'Chambre', field: 'chambre', format: formatChambre },
    'type': { label: 'Type', field: 'type', sort: 'type', format: formatLabel },
    'libelle': { label: 'Nom', field: 'libelle', sort: 'libelle' },
    'membres': { label: 'Nombre de membres', field: 'nombreMembres', sort: 'nombreMembres', defaultSort: true, format: formatNumber },
    'nombreReunionsAnnuelles': { label: 'Nombre de réunions annuelles', sort: 'nombreReunionsAnnuelles', field: 'nombreReunionsAnnuelles', format: formatNumber },
    'actif': { label: 'Actif', field: 'dateFin', sort: 'dateFin', format: formatActif },
}

const organesFilters = [
    { field: "uid", label: 'UID', type: 'text' },
    {
        field: "type", label: "Type", type: 'select', options: [
            { value: 'Circonscription', label: 'Circonscription' },
            { value: 'Groupe politique', label: 'Groupe politique' },
            { value: 'Commission permanente', label: 'Commission permanente' },
            { value: "Commission d'enquête", label: "Commission d'enquête" },
            { value: 'Commission spéciale', label: 'Commission spéciale' },
            { value: 'Délégation', label: 'Délégation' },
            { value: "Mission d'information", label: "Mission d'information" },
            { value: 'Commission mixte paritaire', label: 'Commission mixte paritaire' },
            { value: "Groupe d'amitié", label: "Groupe d'amitié" },
            { value: "Groupe d'étude", label: "Groupe d'étude" },
            { value: 'Office parlementaire', label: 'Office parlementaire' },
            { value: 'Institution', label: 'Institution' },
            { value: 'Ministère', label: 'Ministère' },
            { value: 'Gouvernement', label: 'Gouvernement' },
            { value: 'Organe politique', label: 'Organe politique' },
            { value: 'Organe externe', label: 'Organe externe' },
            { value: 'Autorité administrative indépendante', label: 'Autorité administrative indépendante' },
            { value: 'Assemblée', label: 'Assemblée' },
            { value: 'Parti politique', label: 'Parti politique' }
        ]
    },
    {
        field: "actif", label: "Actif", type: 'select', defaultValue: true, options: [
            { value: null, label: 'Tous' },
            { value: true, label: 'Actif' },
            { value: false, label: 'Non actif' }
        ]
    }
];

export const organeData = {
    key: "organes",
    name: "Organes",
    filters: organesFilters,
    fields: organesFields,
    icon: <OrganeIcon width={20} height={20} />
}