import { formatDate, formatOrgane } from "../formatters";
import { ReunionIcon } from "@logora/parliament.icons.regular_icons";

const formatMotif = (field, item) => {
    return item.resumeOdj || item.convocationOdj || item.objet || "-"
}

const reunionsFields = {
    'timestampDebut': { label: 'Date', field: 'timestampDebut', format: formatDate, sort: "timestampDebut", defaultSort: true },
    'organeReunionRef': { label: 'Organe', field: 'organeReunionRef', include: "organeReunionRef", format: formatOrgane },
    'motif': { label: 'Motif de l\'événement', field: 'motif', format: formatMotif }, // TODO : formatMotif (voir notion)
    'nombreParticipants': { label: 'Nombre de participants', field: 'nombreParticipants' },
}

const reunionsFilters = [
    { field: "uid", label: 'UID', type: 'text' },
];

export const reunionData = {
    key: "reunions",
    name: "Réunions",
    filters: reunionsFilters,
    fields: reunionsFields,
    icon: <ReunionIcon width={20} height={20} />
}