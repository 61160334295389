import React, { useState } from 'react';
import { useSearchParams } from "react-router-dom";
import {
    TextField, Box, IconButton, InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const SearchBar = ({ onSearchChange }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState(searchParams.get("query"));

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
    };

    const handleSearchSubmit = () => {
        onSearchChange(searchQuery);
        setSearchParams(params => {
            params.set("query", searchQuery);
            return params;
        });
    }

    const handleKeyDown = (e) => {
        if (e.keyCode == 13) {
            handleSearchSubmit()
        }
    }

    return (
        <Box sx={{ display: 'flex', width: "60%" }}>
            <TextField
                label="Recherche"
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment>
                            <IconButton onClick={handleSearchSubmit}>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </Box>
    );
};
