import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDataProvider } from 'react-admin';
import { BackLink } from '@logora/debate.action.back_link';
import { Box } from '@mui/material';

export const ScrutinShow = () => {
    const { scrutinUid } = useParams();
    const [scrutin, setScrutin] = useState(null);
    const dataProvider = useDataProvider();

    useEffect(() => {
        fetchElement();
    }, []);

    const fetchElement = useCallback(async () => {
        try {
            const response = await dataProvider.getOne(`scrutins`, {
                id: scrutinUid
            });
            const scrutin = response.data;
            if (scrutin) {
                setScrutin(scrutin);
            }
        } catch (error) {
            console.log(error);
        }
    }, [dataProvider]);

    if (!scrutin) return <div>Loading...</div>;

    return (
        <>
            <BackLink text={"Voir tous les scrutins"} to={"#/parliament/recherche/scrutins"} />
            <Box sx={{ marginTop: "2em" }}>
                {scrutin.uid}
            </Box>
        </>
    );
};
