import { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Button,
    useDataProvider
} from "react-admin";
import styles from './ListNavigation.module.scss'
import cx from 'classnames'

export const ListNavigation = ({ datasets = {}, activeList, onNavChange, navigateOnChange = true, search, filters }) => {
    const navigate = useNavigate();
    const { search: searchParam } = useLocation();
    const dataProvider = useDataProvider();
    const [activePage, setActivePage] = useState(activeList);
    const [totals, setTotals] = useState(
        Object.fromEntries(Object.keys(datasets).map(k => [k, 0]))
    );

    const fetchTotal = (resource) => {
        dataProvider.getList(resource, {
            sort: {},
            pagination: { page: 1, perPage: 1 },
            filter: { ...filters },
            search: search
        })
            .then((res) => {
                if (res.total) {
                    setTotals(prevValues => {
                        return { ...prevValues, [resource]: res.total }
                    });
                }
            })
            .catch((error) => console.error(`Failed to fetch ${resource} total:`, error));
    };

    const fetchTotals = () => {
        Object.keys(datasets).forEach((resource) => {
            fetchTotal(resource);
        });
    };

    useEffect(() => {
        fetchTotals()
    }, [search])

    useEffect(() => {
        fetchTotal(activeList)
    }, [filters])

    const formatTotal = (total) => {
        if (total >= 10000) { return "+10000" }
        return total
    }

    const handleListChange = (page) => {
        setActivePage(page);
        onNavChange(page);
    }

    useEffect(() => {
        if (activeList) {
            setActivePage(activeList)
            if (navigateOnChange) {
                navigate(`/parliament/recherche/${activeList}${searchParam || ''}`)
            }
        }
    }, [activeList])

    const commonProps = {
        variant: "outlined",
        size: "medium"
    }

    if (Object.keys(datasets).length <= 1) {
        return null
    }

    return (
        <div className={styles.navbar}>
            {Object.keys(datasets).map(key => {
                return (
                    <Button
                        key={`nav-${key}`}
                        onClick={() => handleListChange(key)}
                        label={`${datasets[key].name} (${formatTotal(totals[key])})`}
                        className={cx(styles.button, { [styles.active]: activePage === key })}
                        startIcon={datasets[key].icon}
                        {...commonProps}
                    />
                )
            })}
        </div>
    );
}
