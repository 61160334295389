import React from 'react';
import {
    TextField, Checkbox, FormControlLabel, Box, MenuItem
} from '@mui/material';

export const FilterBar = ({ filters, onFilterChange }) => {
    const handleFilterChange = (field, newValue) => {
        onFilterChange(field, newValue);
    };

    const renderFilterField = (filter) => {
        switch (filter.type) {
            case 'date':
                return (
                    <TextField
                        label={filter.label}
                        type="date"
                        defaultValue={filter.defaultValue}
                        onChange={(e) => handleFilterChange(filter, e.target.value)}
                        size='small'
                    />
                );
            case 'boolean':
                return (
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked={filter.defaultValue}
                                onChange={(e) => handleFilterChange(filter, e.target.checked)}
                            />
                        }
                        label={filter.label}
                    />
                );
            case 'select':
                return (
                    <TextField
                        label={filter.label}
                        defaultValue={filter.defaultValue}
                        select
                        SelectProps={{ autoWidth: true }}
                        onChange={(e) => handleFilterChange(filter, e.target.value)}
                        size='small'
                        sx={{ minWidth: "10em" }}
                    >
                        {filter.options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            case 'text':
                return (
                    <TextField
                        label={filter.label}
                        defaultValue={filter.defaultValue}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                handleFilterChange(filter, e.target.value === "" ? null : e.target.value)
                            }
                        }}
                        size='small'
                    />
                );
        }
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", margin: "1em 0em" }}>
            <Box>Filtres</Box>
            <Box sx={{ display: "flex", flexFlow: "row wrap", gap: "1em", marginTop: "0.5em" }}>
                {filters.map((filter) => {
                    if (!filter.hidden) {
                        return (
                            <Box key={`${filter.key}`} display={"flex"}>
                                {renderFilterField(filter)}
                            </Box>
                        )
                    }
                })}
            </Box>
        </Box>
    );
};
