import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDataProvider } from 'react-admin';
import { Box } from '@logora/parliament.ui.box';
import { BackLink } from '@logora/debate.action.back_link';
import { formatDate } from '../formatters';
import { ChamberLabel } from '@logora/parliament.parliament_chamber.chamber_label';
import { MemberBox } from '@logora/parliament.parliament_member.member_box';
import { InterventionBox } from '@logora/parliament.debate.intervention_box';
import styles from "./DebatShow.module.scss"

export const DebatShow = () => {
    const { debatUid } = useParams();
    const [debat, setDebat] = useState(null);
    const [interventions, setInterventions] = useState([]);
    const dataProvider = useDataProvider();

    useEffect(() => {
        fetchElement();
        fetchInterventions()
    }, []);

    const fetchElement = useCallback(async () => {
        try {
            const response = await dataProvider.getOne(`debats`, {
                id: debatUid
            });
            const debat = response.data;
            if (debat) {
                setDebat(debat);
            }
        } catch (error) {
            console.log(error);
        }
    }, [dataProvider]);

    const fetchInterventions = useCallback(async () => {
        try {
            const response = await dataProvider.getList("interventions", {
                filter: { debatRefUid: debatUid },
                include: ['acteurRef'],
                sort: { field: "ordreAbsoluSeance", order: "asc" },
                pagination: { perPage: 1000 }
            });
            const interventions = response.data;
            if (interventions) {
                setInterventions(interventions);
            }
        } catch (error) {
            console.log(error);
        }
    }, [dataProvider])

    const displayIntervention = (intervention) => {
        if (intervention.texte) {
            if (intervention.codeGrammaire === "TITRE_TEXTE_DISCUSSION" || intervention.codeGrammaire.includes("PRESENTATION_")) {
                return (
                    <div className={styles.interventionTitle}>{intervention.texte}</div>
                )
            } else {
                return (
                    <>
                        <InterventionBox intervention={intervention} />
                    </>
                )
            }
        } else {
            return null
        }
    }

    if (!debat) return <div>Loading...</div>;

    return (
        <div className={styles.container}>
            <div className={styles.sticky}>
                <BackLink text={"Voir toutes les débats"} to={"#/parliament/recherche/interventions"} />
                <div className={styles.header}>
                    <ChamberLabel chamber={debat.uid.slice(3, 5)} />
                </div>
                <div className={styles.title}>
                    {debat.quantiemesJournee}
                </div>
            </div>
            <div className={styles.presentation}>
                <div className={styles.box}>
                    <Box title="Auteur" className={styles.boxContainer}>
                        <MemberBox firstName={debat.acteurRef?.prenom} lastName={debat.acteurRef?.nom} uid={debat.acteurRef?.uid} />
                    </Box>
                </div>
                <div className={styles.box}>
                    <Box title="Date de séance" className={styles.boxContainer}>
                        <div>{formatDate("dateSeance", debat)}</div>
                    </Box>
                </div>
            </div>
            {interventions?.length > 0 ?
                <div className={styles.corps}>
                    {interventions.map(displayIntervention)}
                </div>
                :
                <div>Aucune intervention dans le débat</div>
            }
        </div>
    );
};
