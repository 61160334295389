import { useState, useEffect, useCallback } from 'react';

const useFetchCollaborateurs = (dataProvider, mandatUid) => {
    const [collaborateurs, setCollaborateurs] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchElement = useCallback(async () => {
        try {
            setLoading(true);
            setError(null);
            const response = await dataProvider.getOne('mandats', {
                id: mandatUid,
                include: ["collaborateurs"]
            });
            const mandatData = response.data;
            setCollaborateurs([
                ...mandatData.collaborateurs,
            ])
        } catch (error) {
            setError(error);
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, [dataProvider, mandatUid]);

    useEffect(() => {
        if (!mandatUid) {
            return;
        }
        fetchElement();
    }, [fetchElement]);

    return { collaborateurs, loading, error };
};

export default useFetchCollaborateurs;