import React from 'react';
import { Box } from '@mui/material';
import { StatBox } from '@logora/parliament.ui.stat_box';
import { ReportIcon, VoteIcon, SpeechIcon, DocumentIcon } from '@logora/parliament.icons.regular_icons';

const ActeurDashboard = () => {
    return (
        <Box display='flex' flexDirection='row' marginTop='2em' gap='2em' width='100%'>
            <StatBox icon={<DocumentIcon />} mainStat={328} average={284} maxValue={400} minValue={100}/>
            <StatBox icon={<ReportIcon />} mainStat={254} average={412} maxValue={800} minValue={50}/>
            <StatBox icon={<VoteIcon />} mainStat={9} average={16} maxValue={20} minValue={0}/>
            <StatBox icon={<SpeechIcon />} mainStat={7} average={11} maxValue={22} minValue={0}/>
        </Box>
    )
}

export default ActeurDashboard;