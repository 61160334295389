import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDataProvider } from 'react-admin';
import { BackLink } from '@logora/debate.action.back_link';
import { Box } from '@logora/parliament.ui.box';
import { Label } from '@logora/parliament.ui.label';
import { MemberBox } from '@logora/parliament.parliament_member.member_box';
import { formatDate } from '../formatters'
import styles from './DocumentShow.module.scss'

export const DocumentShow = () => {
    const { documentUid } = useParams();
    const [document, setDocument] = useState(null);
    const dataProvider = useDataProvider();

    useEffect(() => {
        fetchElement();
    }, []);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const fetchElement = useCallback(async () => {
        try {
            const response = await dataProvider.getOne(`documents`, {
                id: documentUid,
                include: ["auteurPrincipal", "organeRef"]
            });
            const document = response.data;
            if (document) {
                setDocument(document);
            }
        } catch (error) {
            console.log(error);
        }
    }, [dataProvider]);

    if (!document) return <div>Loading...</div>;

    return (
        <div className={styles.container}>
            <BackLink text={"Voir tous les documents"} to={"#/parliament/recherche/documents"} />
            <div className={styles.header}>
                <Label text={document.classeLibelle} />
            </div>
            <div className={styles.title}>
                {capitalizeFirstLetter(document.titrePrincipalCourt)}
            </div>
            <div className={styles.presentation}>
                <div className={styles.box}>
                    <Box title="Auteur" className={styles.boxContainer}>
                        <MemberBox firstName={document.auteurPrincipal?.prenom} lastName={document.auteurPrincipal?.nom} uid={document.auteurPrincipal?.uid} />
                    </Box>
                </div>
                {document.organeRef &&
                    <div className={styles.box}>
                        <Box title="Commission" className={styles.boxContainer}>
                            <div>{document.organeRef?.libelle}</div>
                        </Box>
                    </div>
                }
                <div className={styles.box}>
                    <Box title="Date de création" className={styles.boxContainer}>
                        <div>{formatDate("dateCreation", document)}</div>
                    </Box>
                </div>
            </div>
            <div className={styles.corps}>
                {document.pdfUrl ?
                    <embed type="application/pdf" src={`${document.pdfUrl}#zoom=80`} width="1100px" height="900px" />
                    :
                    <div className={styles.empty}>Le document n'est pas disponible</div>
                }
            </div>
        </div>
    );
};
