import { useState, useEffect, useCallback } from 'react';

const useFetchActeur = (dataProvider, acteurUid) => {
    const [acteur, setActeur] = useState(null);
    const [adresses, setAdresses] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchElement = useCallback(async () => {
        try {
            setLoading(true);
            setError(null);
            const response = await dataProvider.getOne('acteurs', {
                id: acteurUid,
                include: ["groupeParlementaire", "mandatPrincipal", "circonscription", "commissionPermanente", "adressesElectroniques", "adressesPostales"]
            });
            const acteurData = response.data;
            setActeur(acteurData);
            setAdresses([
                ...acteurData.adressesElectroniques,
                ...acteurData.adressesPostales,
            ])
        } catch (error) {
            setError(error);
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, [dataProvider, acteurUid]);

    useEffect(() => {
        fetchElement();
    }, [fetchElement]);

    return { acteur, adresses, loading, error };
};

export default useFetchActeur;