import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDataProvider } from 'react-admin';
import { BackLink } from '@logora/debate.action.back_link';
import { Box } from '@mui/material';

export const InterventionShow = () => {
    const { interventionId } = useParams();
    const [intervention, setIntervention] = useState(null);
    const dataProvider = useDataProvider();

    useEffect(() => {
        fetchElement();
    }, []);

    const fetchElement = useCallback(async () => {
        try {
            const response = await dataProvider.getOne(`interventions`, {
                id: interventionId
            });
            const intervention = response.data;
            if (intervention) {
                setIntervention(intervention);
            }
        } catch (error) {
            console.log(error);
        }
    }, [dataProvider]);

    if (!intervention) return <div>Loading...</div>;

    return (
        <>
            <BackLink text={"Voir toutes les interventions"} to={"#/parliament/recherche/interventions"} />
            <Box sx={{ marginTop: "2em" }}>
                {intervention.id}
            </Box>
        </>
    );
};
