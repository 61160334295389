import React from "react";
import { Route } from 'react-router-dom';
import { CustomRoutes } from "react-admin";
import { Search } from "./search";
import { ActeurShow } from "./acteurs";
import { QuestionShow } from "./questions";
import { OrganeShow } from "./organes";
import { DocumentShow } from "./documents";
import { InterventionShow } from './interventions';
import { DebatShow } from "./debats";
import { DossierShow } from "./dossiers";
import { ScrutinShow } from "./scrutins";
import { AmendementShow } from "./amendements";
import { ReunionShow } from './reunions'

export const routes = [
    <CustomRoutes>
        <Route path="/parliament/recherche/:dataset?" element={<Search />} />
        <Route path="/parliament/alertes" element={<Search />} />
        <Route path="/parliament/acteurs/:acteurUid" element={<ActeurShow />} />
        <Route path="/parliament/debats/:debatUid" element={<DebatShow />} />
        <Route path="/parliament/questions/:questionUid" element={<QuestionShow />} />
        <Route path="/parliament/organes/:organeUid" element={<OrganeShow />} />
        <Route path="/parliament/documents/:documentUid" element={<DocumentShow />} />
        <Route path="/parliament/interventions/:interventionId" element={<InterventionShow />} />
        <Route path="/parliament/dossiers/:dossierUid" element={<DossierShow />} />
        <Route path="/parliament/scrutins/:scrutinUid" element={<ScrutinShow />} />
        <Route path="/parliament/amendements/:amendementUid" element={<AmendementShow />} />
        <Route path="/parliament/reunions/:reunionUid" element={<ReunionShow />} />
    </CustomRoutes>
]