import BooleanField from "../components/BooleanField";
import { MemberBox } from "@logora/parliament.parliament_member.member_box";
import { ActeurIcon } from "@logora/parliament.icons.regular_icons";
import { formatActeur } from "../formatters";

const mandatsFields = {
    acteur: { label: 'Nom', field: 'acteurRef', include: 'acteurRef', format: formatActeur },
    libQualite: { label: "Qualité", field: "libQualite", sort: "libQualite" },
};

const mandatsFilters = [
    { field: "uid", label: 'UID', type: 'text' },
    {
        field: "actif", label: "Actif", type: 'select', options: [
            { value: null, label: 'Tous' },
            { value: true, label: 'Actif' },
            { value: false, label: 'Non actif' }
        ]
    }
];

export const mandatData = {
    key: "mandats",
    name: "Mandats",
    filters: mandatsFilters,
    fields: mandatsFields,
    icon: <ActeurIcon height={20} width={20} />
}