import React from 'react';
import { Menu, useTranslate } from 'react-admin';
import { Message, Widgets } from '@mui/icons-material';

export const SocialNetworksMenu = () => {
    return (
        <>
            <Menu.Item
                to="/social-networks/contents"
                primaryText="resources.moderable_contents.fields.name"
                leftIcon={<Message />}
            />
            <Menu.Item
                to="/social-networks/integrations"
                primaryText="resources.integrations.fields.name"
                leftIcon={<Widgets />}
            />
        </>
    );
};
