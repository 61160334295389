import React from 'react';
import { Title, useTranslate } from 'react-admin';
import SectionTitle from '../../utils/SectionTitle';
import { IntegrationList } from './IntegrationList';
import { BatchList } from '../batches/BatchList';

export const IntegrationsPage = () => {
    const translate = useTranslate();

    return (
        <>
            <Title title="resources.integrations.section_title" />

            <SectionTitle
                title={translate('resources.integrations.section_title')}
                subtitle={translate('resources.integrations.section_subtitle')}
            />

            <IntegrationList />

            <BatchList />
        </>
    );
};
