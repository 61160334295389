import { acteurData } from '../acteurs';
import { organeData } from '../organes';
import { scrutinData } from '../scrutins';
import { dossierData } from '../dossiers';
import { documentData } from '../documents';
import { questionData } from '../questions';
import { amendementData } from '../amendements';
import { interventionData } from '../interventions';
import { reunionData } from '../reunions';
import { MultiList } from './MultiList';

export const Search = () => {
    const datasets = {
        'dossiers': dossierData,
        'acteurs': acteurData,
        'amendements': amendementData,
        'scrutins': scrutinData,
        'reunions': reunionData,
        'interventions': interventionData,
        'documents': documentData,
        'questions': questionData,
        'organes': organeData
    }

    return  <MultiList datasets={datasets} hideOverflow={true} height='90vh' />
};
